import { API_URL } from "config"
import useCustomMutation from 'common/hooks/useCustomMutation';
import { POST } from 'common/api';


const BASE_URL = API_URL.BE_URL

const sendSupportEmailHelper = (element) => {
    return POST(BASE_URL + 'support/send', element);
}

export const useSendSupportEmail = (onSuccess, onError) => {
    return useCustomMutation(sendSupportEmailHelper, {
        onSuccess,
        onError,
    })
}
