import { TOKEN_TYPE_VERIFY_EMAIL } from 'aas/common/constant';
import { ClearLocalData } from 'common/functions';
import { API_URL } from "config"
import { POST_NOAUTH } from 'common/api';

const BASE_URL = API_URL.AAS_URL;

// No Query Required ------------------------------------------

class AuthService {
    async login(email, password, rememberMe = false) {
        var request = {
            "email": email,
            "password": password,
            "rememberMe": rememberMe,
        }

        return POST_NOAUTH(BASE_URL + 'aas/login', request, null, null);
    }

    async getotp(email) {
        var params = { email: email }
        return POST_NOAUTH(BASE_URL + 'aas/getotp', null, null, { params: params });
    }

    async verifyotp(email, otp) {
        var params = { email: email, otp: otp }
        return POST_NOAUTH(BASE_URL + 'aas/verifyotp', null, null, { params: params });
    }

    async refreshToken(token) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        return POST_NOAUTH(BASE_URL + 'aas/refreshToken', { "token": token }, headers, null);
    }

    async signUp(email, firstName, lastName, password, invitationtoken, otp) {
        var request = {
            "email": email,
            "password": password,
            "firstName": firstName,
            "lastName": lastName,
            "otp": otp
        }

        var params = { invitationtoken: invitationtoken }

        return POST_NOAUTH(BASE_URL + 'aas/v2/sign-up', request, null, { params: params });
    }

    async verifyEmail(token) {
        var request = {
            "token": token,
            "type": TOKEN_TYPE_VERIFY_EMAIL
        }
        return POST_NOAUTH(BASE_URL + 'aas/verifyemail', request, null, null);
    }

    async resendverifyemail(email) {
        var params = { email: email }
        return POST_NOAUTH(BASE_URL + 'aas/resendverifyemail', null, null, { params: params });
    }

    async forgotPassword(email) {
        var params = { email: email }
        return POST_NOAUTH(BASE_URL + 'aas/forgotpassword', null, null, { params: params });
    }

    async resetPassword(email, token, password) {
        var request = {
            "email": email,
            "token": token,
            "password": password
        }
        return POST_NOAUTH(BASE_URL + 'aas/resetpassword', request, null, null);
    }

    async acceptTenantInvitation(token) {
        var params = { token: token }
        return POST_NOAUTH(BASE_URL + 'aas/accepttenantinvite', null, null, { params: params });
    }

    async logout() {
        ClearLocalData();
    }

    //google
    async googleSignUp(code, invitationtoken) {
        let googleHeaders = { 'Content-Type': 'application/json' };

        var request = {
            code: code,
        }

        var params = { invitationtoken: invitationtoken }

        return POST_NOAUTH(BASE_URL + 'aas/googlesignup', request, googleHeaders, { params: params });
    }

    async googleLogin(code, rememberMe = true) {
        let googleHeaders = { 'Content-Type': 'application/json' };

        var data = {
            code: code,
            rememberMe: rememberMe
        }
        return POST_NOAUTH(BASE_URL + 'aas/googlelogin', data, googleHeaders);
    }

    //apple
    async appleLogin(loginRequest, rememberMe = true) {
        let appleHeaders = { 'Content-Type': 'application/json' };

        var data = {
            code: loginRequest.authorization.code,
            id_token: loginRequest.authorization.id_token,
            rememberMe: rememberMe
        }
        return POST_NOAUTH(BASE_URL + 'aas/applogin', data, appleHeaders);
    }

    async appleSignUp(signUpRequest, invitationtoken) {
        let appleHeaders = { 'Content-Type': 'application/json' };
        var params = { invitationtoken: invitationtoken }

        return POST_NOAUTH(BASE_URL + 'aas/applesignup', signUpRequest, appleHeaders, { params: params });
    }

    async appleChangeEmail(loginRequest, email) {
        let appleHeaders = { 'Content-Type': 'application/json' };

        var data = {
            code: loginRequest.authorization.code,
            id_token: loginRequest.authorization.id_token,
            email: email
        }
        return POST_NOAUTH(BASE_URL + 'aas/applechangeemail', data, appleHeaders);
    }
}

export default AuthService;
