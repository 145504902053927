
import { useQueryClient } from 'react-query';

import useCustomMutation from 'common/hooks/useCustomMutation';
import useCustomQuery from 'common/hooks/useCustomQuery';

import { API_URL } from "config"
import { QUERY } from 'aas/common/constant';
import { GetTenantId } from 'aas/common/functions';
import { GET, POST } from 'common/api';


const BASE_URL = API_URL.NOTIFICATION_URL

const getTenantNotificationsHelper = () => {
    return GET(BASE_URL + 'notification/getbytenant');
}

const deleteTenantNotificationHelper = (id) => {
    var data = {
        id: id,
        tenantId: GetTenantId(),
    }

    return POST(BASE_URL + 'notification/deletefromtenant', data);
}

export const useTenantNotifications = (onSuccess, onError) => {
    return useCustomQuery([QUERY.TENANT_NOTIFICATION], getTenantNotificationsHelper, {
        onSuccess,
        onError,
        select: (data) => { return data.data },
        refetchInterval: 60000
    })
}

export const useDeleteTenantNotification = (onSuccess, onError) => {
    const queryClient = useQueryClient()
    return useCustomMutation(deleteTenantNotificationHelper, {
        onSuccess,
        onError,
        onSettled: () => {
            queryClient.invalidateQueries(QUERY.TENANT_NOTIFICATION)
        }
    })
}


const getPlatformNotificationsHelper = () => { return GET(BASE_URL + 'pnotify/getall'); }
export const usePlatformNotifications = (onSuccess, onError) => {
    return useCustomQuery([QUERY.PLATFORM_NOTIFICATION], getPlatformNotificationsHelper, {
        onSuccess,
        onError,
        select: (data) => { return data.data }
    })
}

const deletePlatformNotificationHelper = (id) => {
    var parms = { id: id }
    return POST(BASE_URL + 'pnotify/delete', null, null, { params: parms });
}
export const useDeletePlatformNotification = (onSuccess, onError) => {
    const queryClient = useQueryClient()
    return useCustomMutation(deletePlatformNotificationHelper, {
        onSuccess,
        onError,
        onSettled: () => {
            queryClient.invalidateQueries(QUERY.PLATFORM_NOTIFICATION)
        }
    })
}

const createPlatformNotificationHelper = (element) => {
    return POST(BASE_URL + 'pnotify/create', element);
}
export const useCreatePlatformNotification = (onSuccess, onError) => {
    const queryClient = useQueryClient()
    return useCustomMutation(createPlatformNotificationHelper, {
        onSuccess,
        onError,
        onSettled: () => {
            queryClient.invalidateQueries(QUERY.PLATFORM_NOTIFICATION)
        }
    })
}