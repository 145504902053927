import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import validator from 'validator';
// material-ui
// import { useTheme } from '@mui/material/styles';
import {
    Divider, Grid, Typography, Button, Checkbox, FormControlLabel,
    TextField, FormHelperText
} from "@mui/material";

// project imports
import { gridSpacing } from 'common/constant';
import BlueBgWrapper from 'base/layout/BlueBgWrapper';
import AnimateButton from 'ui-component/common/AnimateButton';
import AuthFooter from 'ui-component/AuthFooter';
import MainCard from "ui-component/cards/MainCard";
import SubCard from 'ui-component/cards/SubCard'
import ZPromptConfirmation from 'ui-component/ZPromptConfirmation';
import ZBackdrop from 'ui-component/ZBackdrop';

import AuthService from "aas/services/AuthService";

import { ErrorTranslator } from 'aas/common/functions';

// assets
import Logo from 'ui-component/common/Logo';
import { toast } from 'react-toastify';
import { isStringEmpty } from 'common/functions';


// ===============================|| AUTH3 - REGISTER ||=============================== //
const AppleRegistration = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState(false);
    const [problemMsg, setProblemMsg] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState(false);

    const [token, setToken] = useState(null);

    const authService = new AuthService();

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [checked, setChecked] = useState(true);

    const [emailValid, setEmailValid] = useState(true);
    const [confirmEmailValid, setConfirmEmailValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    const [code, setCode] = useState(null);
    const [idToken, setIdToken] = useState(null);

    const isValid = () => {
        if (!emailValid) {
            setErrorMsg('Please enter correct email address.')
            return false
        }

        if (!confirmEmailValid) {
            setErrorMsg('Please enter correct email address.')
            return false
        }

        setErrorMsg('')
        return true
    }

    useEffect(() => {
        console.log(location.state)
        if (location.state != null && location.state.loginResponse != null) {
            setToken(location.state.token)
            setCode(location.state.loginResponse.code)
            setIdToken(location.state.loginResponse.id_token)

        } else {
            toast.error(ErrorTranslator("Login Error, please retry"));
            navigate("../login")
        }
    }, [location, setToken, setCode, setIdToken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValid()) {
            return
        }

        let signUpRequest = {
            code: code,
            id_token: idToken,
            email: email,
            firstName: firstName,
            lastName: lastName
        }
        console.log(signUpRequest)
        setLoading(true)
        authService.appleSignUp(signUpRequest, token).then(
            async (response) => {
                console.log(response)
                if (response.status === 200 && response.data.code === "SUCCESS") {
                    setTimeout(() => {
                        setLoading(false)
                        setConfirmMsg('An email has been sent to your email address, please check your email to activate the account.')
                        setConfirm(true)
                    }, 500);
                } else {
                    setErrorMsg(ErrorTranslator(response.data.data))
                    setLoading(false)
                }
            }, error => {
                setErrorMsg(error)
                setLoading(false)
            })
    }

    return (
        <BlueBgWrapper>
            <ZBackdrop open={isLoading} />
            <ZPromptConfirmation open={confirm}
                fullWidth
                title={"Please confirm"}
                text={confirmMsg}
                confirmButtonText={'Ok'}
                onClickConfirm={() => { setConfirm(false); navigate("../login") }}
            />
            <ZPromptConfirmation open={problemMsg}
                fullWidth
                title={"Please confirm"}
                text={"If you still encounter any difficulties with signing in or signing up, resetting your password, or managing your email, please contact support@eceiptmobile.com."}
                confirmButtonText={'Ok'}
                onClickConfirm={() => { setProblemMsg(false); }}
            />

            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ position: 'absolute', left: { lg: '40px', md: '15px' }, top: { lg: '40px', md: '15px' }, display: { md: 'block', xs: 'none' } }}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Typography color={'white'} variant='h1' sx={{ fontFamily: 'Montserrat' }}>Ecepit</Typography>
                                    <Typography color={'white'} variant='subtitle1' sx={{ fontFamily: 'Montserrat' }}>Manage your business with just a few clicks</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0, pl: { lg: 80, md: 0 } }} >
                            <MainCard sx={{
                                maxWidth: { xs: 400, lg: 475 },
                                margin: { xs: 2.5, md: 3 },
                                "& > *": { flexGrow: 1, flexBasis: "50%", },
                            }} content={false}>

                                <SubCard sx={{ border: 0 }} disableHover noPadding>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <Logo />
                                        </Grid>
                                    </Grid>
                                </SubCard>

                                <SubCard sx={{ border: 0 }} contentSX={{ pt: 1 }} disableHover>
                                    <Grid item xs={12} sx={{ mb: 2 }} container alignItems="center" justifyContent="center">
                                        <Typography variant="subtitle1">Sign Up with Apple</Typography>
                                    </Grid>

                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12} >
                                                <Typography variant="subtitle1" display="inline">
                                                    APPLE ID Verification : &nbsp;
                                                </Typography>

                                                {isStringEmpty(code) || isStringEmpty(idToken) ? <Typography variant="h4" display="inline" color={theme.palette.error.dark}>Fail</Typography> :
                                                    <Typography variant="h4" display="inline" color={theme.palette.success.dark}>Success</Typography>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Email Address"
                                                    value={email ?? ""}
                                                    error={!emailValid}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setEmailValid(validator.isEmail(e.target.value))
                                                    }}
                                                    helperText={!emailValid ? 'Invalid Email Format.' : ''}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Confirm Email Address"
                                                    value={confirmEmail ?? ""}
                                                    error={!confirmEmailValid}
                                                    onChange={(e) => {
                                                        setConfirmEmail(e.target.value)
                                                        setConfirmEmailValid(email.toLocaleLowerCase() === e.target.value.toLocaleLowerCase())
                                                    }}
                                                    helperText={!confirmEmailValid ? 'Mismatch Email.' : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1">
                                                    Email is crucial for user registration. Please ensure the email provided is valid and capable of receiving messages.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="First Name"
                                                    value={firstName ?? ""}
                                                    onChange={(e) => { setFirstName(e.target.value) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Last Name"
                                                    value={lastName ?? ""}
                                                    onChange={(e) => { setLastName(e.target.value) }}
                                                />
                                            </Grid>

                                            {errorMsg !== null && errorMsg.length > 0 ? <>
                                                <Grid item xs={12} >
                                                    <FormHelperText error>{errorMsg}</FormHelperText>
                                                </Grid></> : null}

                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                                                    }
                                                    label={
                                                        <Typography variant="subtitle1">
                                                            Agree with &nbsp;
                                                            <Typography variant="subtitle1" component={Link} to="#">
                                                                Terms & Condition.
                                                            </Typography>
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {isStringEmpty(code) || isStringEmpty(idToken) ? null :
                                                    <AnimateButton>
                                                        <Button disableElevation disabled={false} fullWidth size="large" type="submit" variant="contained" color="primary"                              >
                                                            Sign Up
                                                        </Button>
                                                    </AnimateButton>}
                                            </Grid>
                                        </Grid>
                                    </form>
                                </SubCard>

                                <SubCard sx={{ border: 0 }} disableHover>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography component={Link} to="/login" variant="subtitle1" sx={{ textDecoration: "none" }}>
                                                        Already have an account?
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography component={Button} variant="subtitle1" sx={{ textDecoration: "none" }} onClick={() => setProblemMsg(true)}>
                                                        Problem With Sign Up
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </MainCard>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </BlueBgWrapper>
    );
};

export default AppleRegistration;
