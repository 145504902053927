import { API_URL } from "config"
import useCustomQuery from 'common/hooks/useCustomQuery';
import { QUERY } from 'aas/common/constant';
import { useQueryClient } from 'react-query';
import useCustomMutation from 'common/hooks/useCustomMutation';
import { GET, POST, POST_FORM_DATA } from 'common/api';

const BASE_URL = API_URL.AAS_URL;

const getOwnInfoHelper = () => {
  return GET(BASE_URL + 'aas/user/getowninfo');
}

const getUserLanguageHelper = () => {
  return GET(BASE_URL + 'aas/user/getuserlang');
}

const updateUserLanguageHelper = (lang) => {
  var parms = { lang: lang }
  return POST(BASE_URL + 'aas/user/updateuserlang', null, null, { params: parms });
}

const updateProfileImageHelper = (file) => {
  const data = new FormData();
  data.append("image", file);

  return POST_FORM_DATA(BASE_URL + 'aas/user/updateprofileimage', data);
}

const removeProfileImageHelper = () => {
  return POST(BASE_URL + 'aas/user/removeprofileimage', null);
}

const updateUserOwnInfoHelper = (data) => {
  return POST(BASE_URL + 'aas/user/updateuserowninfo', data);
}

const changePasswordHelper = (data) => {
  return POST(BASE_URL + 'aas/user/changepassword', data);
}

export const useOwnInfo = (onSuccess, onError) => {
  // const queryClient = useQueryClient()
  return useCustomQuery([QUERY.OWN_INFO], getOwnInfoHelper, {
    onSuccess,
    onError,
    select: (data) => { return data.data },
    enabled: true
  })
}

export const useUserLanguage = (onSuccess, onError) => {
  return useCustomQuery([QUERY.USER_LANGUAGE], getUserLanguageHelper, {
    onSuccess,
    onError,
    select: (data) => { return data.data },
  })
}

export const useUpdateUserLanguage = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateUserLanguageHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.USER_LANGUAGE)
    }
  })
}

export const useUpdateProfileImage = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateProfileImageHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.OWN_INFO)
    }
  })
}

export const useRemoveProfileImage = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(removeProfileImageHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.OWN_INFO)
    }
  })
}

export const useUpdateOwnInfo = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(updateUserOwnInfoHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.OWN_INFO)
    }
  })
}

export const useChangePassword = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  return useCustomMutation(changePasswordHelper, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY.OWN_INFO)
    }
  })
}

class UserService {
  async getOwnInfo() {
    return GET(BASE_URL + 'aas/user/getowninfo');
  }
}

export default UserService;
