import axios from 'axios';
import { API_URL } from 'config';

import { LsTokenExpire, LsUserRefreshToken, LsUserRememberMe, LsUserTenant, LsUserToken } from 'aas/common/constant';
import { httpClient } from './api';

const BASE_URL = API_URL.AAS_URL;

export const AuthInterceptors = {
    setupInterceptors: () => {
        httpClient.interceptors.request.use(
            async (config) => {
                // YOU CAN ADD CODE TO OPEN A LOADER HERE
                var expirationTime = localStorage.getItem(LsTokenExpire);
                var refreshToken = localStorage.getItem(LsUserRefreshToken);
                var rememberMe = localStorage.getItem(LsUserRememberMe);

                var expiring = expirationTime - (30 * 60 * 1000) < new Date().getTime()
                var tenantId = localStorage.getItem(LsUserTenant);

                if (Boolean(rememberMe) && refreshToken && expiring) {
                    // console.log("Start Refresh Token", config)
                    let newToken = ''
                    let expirationTime = 0
                    try {
                        const Headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                        const response = await axios.post(BASE_URL + 'aas/refreshToken', { token: refreshToken }, { headers: Headers });
                        newToken = response.data.data.token;
                        expirationTime = response.data.data.expiration_time;
                    } catch (err) {
                        if (err.response.status === 401) {
                            // IF THE RESPONSE IS 401 FROM THE refreshToken ROUTE, THEN IT MEANS THAT THE refreshToken ALSO HAS EXPIRED. THEN WE REDIRECT USER TO LOGIN SCREEN TO GET A NEW REFRESH_TOKEN
                            window.location.replace('/login');
                            // const auth = useAuth();
                            // auth.signOut();
                        }
                        return Promise.reject(err);
                    }

                    // console.log(newToken, expirationTime)
                    localStorage.setItem(LsUserToken, newToken);
                    localStorage.setItem(LsTokenExpire, expirationTime);

                    config.headers.Authorization = `Bearer ${newToken}`;
                    config.headers.tenantId = tenantId;
                    // console.log("Refresh token config:", config)
                    return config

                } else {
                    const token = localStorage.getItem(LsUserToken);;

                    config.headers.Authorization = `Bearer ${token}`;
                    config.headers.tenantId = tenantId;

                    // console.log('Normal Config Used', config)
                    return config
                }
            },
            function (err) {
                console.log("Error:", err)
                return Promise.reject(err);
            }
        );

        httpClient.interceptors.response.use(
            (response) => {
                // console.log('Response', JSON.stringify(response, null, 2))

                // YOU CAN ADD CODE TO CLOSE A LOADER HERE

                return response;
            },
            (error) => {
                // console.log('Response', JSON.stringify(error, null, 2))
                // YOU CAN ADD CODE TO CLOSE A LOADER HERE

                return Promise.reject(error);
            }
        );
    },
};
